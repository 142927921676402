<template>
  <div id="next-stop-settings">
    <div class="page-title px-4">
      <h2>
        <span class="me-2">Settings</span>
      </h2>
      <save-button :save-action="saveSettings" />
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>
    <div>
      <v-tabs v-model="currentTab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.icon">
          <v-icon class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>
            {{ tab.title }}
          </span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <!-- TTS Segments -->
        <v-tab-item>
          <v-card>
            <v-card-text>
              <div class="tts-standard-segments">
                <v-form>
                  <v-row>
                    <v-col>
                      <h2 class="text-left text-xl font-weight-semibold label">Next stop introduction</h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-textarea
                        v-model="localSettings.intro_tts"
                        label="Next stop introduction TTS"
                        placeholder="eg. Next stop..."
                        hint=""
                        hide-details
                        rows="2"
                        outlined
                      >
                      </v-textarea>
                      <tooltip-for-text-to-speech-field></tooltip-for-text-to-speech-field>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center align-center">
                      <v-btn class="mb-8" fab dark small color="primary" @click="TTSpreview(localSettings.intro_tts)">
                        <v-icon x-large>
                          {{ icons.mdiPlayCircle }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h2 class="text-left text-xl font-weight-semibold label">Transfers introduction</h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-textarea
                        v-model="localSettings.transfer_tts"
                        label="Transfers introduction TTS"
                        placeholder="eg. Transfer routes..."
                        rows="2"
                        hide-details
                        outlined
                      ></v-textarea>
                      <tooltip-for-text-to-speech-field></tooltip-for-text-to-speech-field>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center align-center">
                      <v-btn
                        class="mb-8"
                        fab
                        dark
                        small
                        color="primary"
                        @click="TTSpreview(localSettings.transfer_tts)"
                      >
                        <v-icon x-large>
                          {{ icons.mdiPlayCircle }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h2 class="text-left text-xl font-weight-semibold label">Last stop message</h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-textarea
                        v-model="localSettings.last_stop_tts"
                        label="Last stop message TTS"
                        placeholder="eg. This is the last stop for this service"
                        rows="2"
                        hide-details
                        outlined
                      ></v-textarea>
                      <tooltip-for-text-to-speech-field></tooltip-for-text-to-speech-field>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center align-center">
                      <v-btn
                        class="mb-8"
                        fab
                        dark
                        small
                        color="primary"
                        @click="TTSpreview(localSettings.last_stop_tts)"
                      >
                        <v-icon x-large>
                          {{ icons.mdiPlayCircle }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <h2 class="text-left text-xl font-weight-semibold label">
                        Additional announcements introduction
                      </h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-textarea
                        v-model="localSettings.additional_intro_tts"
                        label="Additional announcements introduction TTS"
                        placeholder="eg. Stop here for..."
                        rows="2"
                        hide-details
                        outlined
                      ></v-textarea>
                      <tooltip-for-text-to-speech-field></tooltip-for-text-to-speech-field>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center align-center">
                      <v-btn
                        class="mb-8"
                        fab
                        dark
                        small
                        color="primary"
                        @click="TTSpreview(localSettings.additional_intro_tts)"
                      >
                        <v-icon x-large>
                          {{ icons.mdiPlayCircle }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>

                <audio :src="TTS" autoplay></audio>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- Global Volume -->
        <v-tab-item>
          <v-card>
            <v-card-text>
              <div class="global-volume">
                <h2 class="text-left text-xl font-weight-semibold label">Global volume</h2>

                <v-row class="pt-10 px-10">
                  <v-col cols="12" md="3"> Global off-peak volume </v-col>
                  <v-col cols="1">
                    <span class="font-weight-medium me-2">
                      <v-icon class="me-1" size="18" @click="localSettings.offpeak_volume = 0">
                        {{ localSettings.offpeak_volume == 0 ? icons.mdiVolumeOff : icons.mdiVolumeHigh }}
                      </v-icon>
                    </span>
                  </v-col>
                  <v-col cols="10" md="8">
                    <v-slider v-model="localSettings.offpeak_volume" color="primary" thumb-label="always"></v-slider>
                  </v-col>
                </v-row>
                <v-row class="px-10">
                  <v-col cols="12" md="3"> Global peak volume </v-col>
                  <v-col cols="1">
                    <span class="font-weight-medium me-2">
                      <v-icon class="me-1" size="18" @click="localSettings.peak_volume = 0">
                        {{ localSettings.peak_volume == 0 ? icons.mdiVolumeOff : icons.mdiVolumeHigh }}
                      </v-icon>
                    </span>
                  </v-col>
                  <v-col cols="10" md="8">
                    <v-slider v-model="localSettings.peak_volume" color="primary" thumb-label="always"></v-slider>
                  </v-col>
                </v-row>
                <v-row class="px-10">
                  <v-col cols="12" md="3"> Global quiet time volume </v-col>
                  <v-col cols="1">
                    <span class="font-weight-medium me-2">
                      <v-icon class="me-1" size="18" @click="localSettings.quiet_volume = 0">
                        {{ localSettings.quiet_volume == 0 ? icons.mdiVolumeOff : icons.mdiVolumeHigh }}
                      </v-icon>
                    </span>
                  </v-col>
                  <v-col cols="10" md="8">
                    <v-slider v-model="localSettings.quiet_volume" color="primary" thumb-label="always"></v-slider>
                  </v-col>
                </v-row>

                <!-- Weekday peak volume times-->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">Weekday peak times</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekday_peak"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeWeekdayPeak(item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-card class="px-10 py-4" elevation="2">
                      <v-row>
                        <v-col> <h3>Add a new weekday peak time</h3> </v-col>
                      </v-row>
                      <v-row class="d-flex align-center">
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekdayPeakStart" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekdayPeak.start_time"
                                label="Start"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekdayPeakStart"
                              v-model="newWeekdayPeak.start_time"
                              color="primary"
                              full-width
                              @update:modelValue="newWeekdayPeakError = 'pending'"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekdayPeak.start_time = ''
                                  newWeekdayPeakStart = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekdayPeak')
                                  newWeekdayPeakStart = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="2"> To </v-col>
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekdayPeakEnd" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekdayPeak.end_time"
                                label="End"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekdayPeakEnd"
                              v-model="newWeekdayPeak.end_time"
                              color="primary"
                              full-width
                              @update:modelValue="newWeekdayPeakError = 'pending'"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekdayPeak.end_time = ''
                                  newWeekdayPeakEnd = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekdayPeak')
                                  newWeekdayPeakEnd = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="success"
                            :disabled="!newWeekdayPeak.start_time || !newWeekdayPeak.end_time || !!newWeekdayPeakError"
                            @click="saveNewWeekdayPeak()"
                          >
                            <v-icon class="d-flex justify-center align-center" size="18">
                              {{ icons.mdiCheck }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="primary"
                            @click="newWeekdayPeak = { ...VolumePeriodModel }"
                          >
                            <v-icon class="d-flex justify-center align-center" size="28">
                              {{ icons.mdiCloseCircleOutline }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-col v-if="newWeekdayPeakError">
                        <span class="font-weight-semibold error--text">{{ newWeekdayPeakError }}</span>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Weekend peak volume times-->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">Weekend peak times</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekend_peak"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeWeekendPeak(item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-card class="px-10 py-4" elevation="2">
                      <v-row>
                        <v-col> <h3>Add a new weekend peak time</h3> </v-col>
                      </v-row>
                      <v-row class="d-flex align-center">
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekendPeakStart" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekendPeak.start_time"
                                label="Start"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekendPeakStart"
                              v-model="newWeekendPeak.start_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekendPeak.start_time = ''
                                  newWeekendPeakStart = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekendPeak')
                                  newWeekendPeakStart = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="2"> To </v-col>
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekendPeakEnd" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekendPeak.end_time"
                                label="End"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekendPeakEnd"
                              v-model="newWeekendPeak.end_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekendPeak.end_time = ''
                                  newWeekendPeakEnd = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekendPeak')
                                  newWeekendPeakEnd = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="success"
                            :disabled="!newWeekendPeak.start_time || !newWeekendPeak.end_time || !!newWeekendPeakError"
                            @click="saveNewWeekendPeak"
                          >
                            <v-icon class="d-flex justify-center align-center" size="18">
                              {{ icons.mdiCheck }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="primary"
                            @click="newWeekendPeak = { ...VolumePeriodModel }"
                          >
                            <v-icon class="d-flex justify-center align-center" size="28">
                              {{ icons.mdiCloseCircleOutline }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-col v-if="newWeekendPeakError">
                        <span class="font-weight-semibold error--text">{{ newWeekendPeakError }}</span>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Weekday quiet volume times-->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">Weekday quiet times</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekday_quiet"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeWeekdayQuiet(item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-card class="px-10 py-4" elevation="2">
                      <v-row>
                        <v-col> <h3>Add a new weekday quiet time</h3> </v-col>
                      </v-row>
                      <v-row class="d-flex align-center">
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekdayQuietStart" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekdayQuiet.start_time"
                                label="Start"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekdayQuietStart"
                              v-model="newWeekdayQuiet.start_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekdayQuiet.start_time = ''
                                  newWeekdayQuietStart = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekdayQuiet')
                                  newWeekdayQuietStart = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="2"> To </v-col>
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekdayQuietEnd" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekdayQuiet.end_time"
                                label="End"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekdayQuietEnd"
                              v-model="newWeekdayQuiet.end_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekdayQuiet.end_time = ''
                                  newWeekdayQuietEnd = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekdayQuiet')
                                  newWeekdayQuietEnd = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="success"
                            :disabled="
                              !newWeekdayQuiet.start_time || !newWeekdayQuiet.end_time || !!newWeekdayQuietError
                            "
                            @click="saveNewWeekdayQuiet()"
                          >
                            <v-icon class="d-flex justify-center align-center" size="18">
                              {{ icons.mdiCheck }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="primary"
                            @click="newWeekdayQuiet = { ...VolumePeriodModel }"
                          >
                            <v-icon class="d-flex justify-center align-center" size="28">
                              {{ icons.mdiCloseCircleOutline }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-col v-if="newWeekdayQuietError">
                        <span class="font-weight-semibold error--text">{{ newWeekdayQuietError }}</span>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Weekend quiet volume times-->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">Weekend quiet times</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekend_quiet"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeWeekendQuiet(item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-card class="px-10 py-4" elevation="2">
                      <v-row>
                        <v-col> <h3>Add a new weekend quiet time</h3> </v-col>
                      </v-row>
                      <v-row class="d-flex align-center">
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekendQuietStart" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekendQuiet.start_time"
                                label="Start"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekendQuietStart"
                              v-model="newWeekendQuiet.start_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekendQuiet.start_time = ''
                                  newWeekendQuietStart = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekendQuiet')
                                  newWeekendQuietStart = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="2"> To </v-col>
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekendQuietEnd" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekendQuiet.end_time"
                                label="End"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekendQuietEnd"
                              v-model="newWeekendQuiet.end_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekendQuiet.end_time = ''
                                  newWeekendQuietEnd = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekendQuiet')
                                  newWeekendQuietEnd = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="success"
                            :disabled="
                              !newWeekendQuiet.start_time || !newWeekendQuiet.end_time || !!newWeekendQuietError
                            "
                            @click="saveNewWeekendQuiet"
                          >
                            <v-icon class="d-flex justify-center align-center" size="18">
                              {{ icons.mdiCheck }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="primary"
                            @click="newWeekendQuiet = { ...VolumePeriodModel }"
                          >
                            <v-icon class="d-flex justify-center align-center" size="28">
                              {{ icons.mdiCloseCircleOutline }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-col v-if="newWeekendQuietError">
                        <span class="font-weight-semibold error--text">{{ newWeekendQuietError }}</span>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>
                <!-- end of times -->
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- Announcment Settings -->
        <v-tab-item>
          <v-card class="mb-4">
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">
                      Maximum length to display stop details during announcement
                    </h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="localSettings.max_display_duration"
                      label="Seconds"
                      outlined
                      dense
                      hint=""
                      hide-details
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">
                      Minimum length to display stop details during announcement
                    </h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="localSettings.min_display_duration"
                      label="Seconds"
                      outlined
                      dense
                      hint=""
                      hide-details
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card class="mb-4">
            <v-card-text>
              <!-- Weekday no transfer announcement times -->
              <v-row>
                <v-col>
                  <h2 class="text-left text-xl font-weight-semibold label">Weekday no transfer announcement times</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col offset="1" cols="9">
                  <v-data-table
                    :headers="timePeriodHeaders"
                    :items="localSettings.weekday_no_transfer"
                    :single-expand="false"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.remove="{ item }">
                      <v-btn elevation="0" fab x-small>
                        <v-icon
                          class="d-flex justify-center align-center"
                          size="22"
                          color="primary"
                          @click="removeWeekdayNoTransfer(item)"
                        >
                          {{ icons.mdiCloseCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-divider class="mt-4 mb-10"></v-divider>
              <v-row>
                <v-col offset="1" cols="9">
                  <v-row>
                    <v-col> <h3>Add a new weekday no transfer announcement time</h3> </v-col>
                  </v-row>
                  <v-row class="d-flex align-center">
                    <v-col cols="4">
                      <v-dialog ref="dialog" v-model="newWeekdayNoTransferStart" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="newWeekdayNoTransfer.start_time"
                            label="Start"
                            :prepend-icon="icons.mdiClockTimeFourOutline"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="newWeekdayNoTransferStart"
                          v-model="newWeekdayNoTransfer.start_time"
                          color="primary"
                          full-width
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              newWeekdayNoTransfer.start_time = ''
                              newWeekdayNoTransferStart = false
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              validateTimeRange('newWeekdayNoTransfer')
                              newWeekdayNoTransferStart = false
                            "
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="2"> To </v-col>
                    <v-col cols="4">
                      <v-dialog ref="dialog" v-model="newWeekdayNoTransferEnd" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="newWeekdayNoTransfer.end_time"
                            label="End"
                            :prepend-icon="icons.mdiClockTimeFourOutline"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="newWeekdayNoTransferEnd"
                          v-model="newWeekdayNoTransfer.end_time"
                          color="primary"
                          full-width
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              newWeekdayNoTransfer.end_time = ''
                              newWeekdayNoTransferEnd = false
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              validateTimeRange('newWeekdayNoTransfer')
                              newWeekdayNoTransferEnd = false
                            "
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center align-center">
                      <v-btn
                        class="mb-8"
                        fab
                        dark
                        x-small
                        color="success"
                        :disabled="
                          !newWeekdayNoTransfer.start_time ||
                          !newWeekdayNoTransfer.end_time ||
                          !!newWeekdayNoTransferError
                        "
                        @click="saveNewWeekdayNoTransfer()"
                      >
                        <v-icon class="d-flex justify-center align-center" size="18">
                          {{ icons.mdiCheck }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center align-center">
                      <v-btn
                        class="mb-8"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="newWeekdayNoTransfer = { ...VolumePeriodModel }"
                      >
                        <v-icon class="d-flex justify-center align-center" size="28">
                          {{ icons.mdiCloseCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-col v-if="newWeekdayNoTransferError">
                    <span class="font-weight-semibold error--text">{{ newWeekdayNoTransferError }}</span>
                  </v-col>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mb-4">
            <v-card-text>
              <!-- Weekend no transfer announcement times -->
              <v-row>
                <v-col>
                  <h2 class="text-left text-xl font-weight-semibold label">Weekend no transfer announcement times</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col offset="1" cols="9">
                  <v-data-table
                    :headers="timePeriodHeaders"
                    :items="localSettings.weekend_no_transfer"
                    :single-expand="false"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.remove="{ item }">
                      <v-btn elevation="0" fab x-small>
                        <v-icon
                          class="d-flex justify-center align-center"
                          size="22"
                          color="primary"
                          @click="removeWeekendNoTransfer(item)"
                        >
                          {{ icons.mdiCloseCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-divider class="mt-4 mb-10"></v-divider>
              <v-row>
                <v-col offset="1" cols="9">
                  <v-row>
                    <v-col> <h3>Add a new weekend no transfer announcement time</h3> </v-col>
                  </v-row>
                  <v-row class="d-flex align-center">
                    <v-col cols="4">
                      <v-dialog ref="dialog" v-model="newWeekendNoTransferStart" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="newWeekendNoTransfer.start_time"
                            label="Start"
                            :prepend-icon="icons.mdiClockTimeFourOutline"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="newWeekendNoTransferStart"
                          v-model="newWeekendNoTransfer.start_time"
                          color="primary"
                          full-width
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              newWeekendNoTransfer.start_time = ''
                              newWeekendNoTransferStart = false
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              validateTimeRange('newWeekendNoTransfer')
                              newWeekendNoTransferStart = false
                            "
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="2"> To </v-col>
                    <v-col cols="4">
                      <v-dialog ref="dialog" v-model="newWeekendNoTransferEnd" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="newWeekendNoTransfer.end_time"
                            label="End"
                            :prepend-icon="icons.mdiClockTimeFourOutline"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="newWeekendNoTransferEnd"
                          v-model="newWeekendNoTransfer.end_time"
                          color="primary"
                          full-width
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              newWeekendNoTransfer.end_time = ''
                              newWeekendNoTransferEnd = false
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              validateTimeRange('newWeekendNoTransfer')
                              newWeekendNoTransferEnd = false
                            "
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center align-center">
                      <v-btn
                        class="mb-8"
                        fab
                        dark
                        x-small
                        color="success"
                        :disabled="
                          !newWeekendNoTransfer.start_time ||
                          !newWeekendNoTransfer.end_time ||
                          !!newWeekendNoTransferError
                        "
                        @click="saveNewWeekendNoTransfer"
                      >
                        <v-icon class="d-flex justify-center align-center" size="18">
                          {{ icons.mdiCheck }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center align-center">
                      <v-btn
                        class="mb-8"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="newWeekendNoTransfer = { ...VolumePeriodModel }"
                      >
                        <v-icon class="d-flex justify-center align-center" size="28">
                          {{ icons.mdiCloseCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-col v-if="newWeekendNoTransferError">
                    <span class="font-weight-semibold error--text">{{ newWeekendNoTransferError }}</span>
                  </v-col>
                </v-col>
              </v-row>
              <!-- end of times -->
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- Global Pronunciations -->
        <v-tab-item>
          <pronunciation-list />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import {
  mdiCog,
  mdiTextToSpeech,
  mdiVolumeHigh,
  mdiPlayCircle,
  mdiVolumeOff,
  mdiClockOutline,
  mdiCalendarRemoveOutline,
  mdiCloseCircleOutline,
  mdiCheck,
  mdiBookOpenVariant,
  mdiSpeakerWireless,
} from "@mdi/js"
import { ref } from "@vue/composition-api"
import { mapActions, mapState } from "vuex"
import SaveButton from "../components/SaveButton.vue"
import PronunciationList from "./components/PronunciationList.vue"
import TooltipForTextToSpeechField from "../components/TooltipForTextToSpeechField.vue"

import store from "@/store"

export default {
  name: "Setting",
  metaInfo: {
    title: "Settings",
  },
  components: { SaveButton, TooltipForTextToSpeechField, PronunciationList },
  data() {
    return {
      currentTab: "",
      timePeriodHeaders: [
        { text: "From", value: "start_time", sortable: false },
        { text: "To", value: "end_time", sortable: false },
        { text: "Remove", value: "remove", sortable: false },
      ],
      newWeekdayPeakStart: false,
      newWeekdayPeakEnd: false,
      newWeekendPeakStart: false,
      newWeekendPeakEnd: false,
      newWeekdayQuietStart: false,
      newWeekdayQuietEnd: false,
      newWeekendQuietStart: false,
      newWeekendQuietEnd: false,
      newWeekdayPeakError: false,
      newWeekendPeakError: false,
      newWeekdayQuietError: false,
      newWeekendQuietError: false,
      newWeekdayNoTransferStart: false,
      newWeekdayNoTransferEnd: false,
      newWeekendNoTransferStart: false,
      newWeekendNoTransferEnd: false,
      newWeekdayNoTransferError: false,
      newWeekendNoTransferError: false,
      tabs: [
        { title: "TTS segments", icon: mdiTextToSpeech },
        { title: "Global volume", icon: mdiVolumeHigh },
        { title: "Announcement settings ", icon: mdiSpeakerWireless },
        { title: "Global pronunciations", icon: mdiBookOpenVariant },
      ],
      localSettings: {},
      TTS: null,
      panel: [1],
      VolumePeriodModel: {
        start_time: "",
        end_time: "",
      },
      newWeekdayPeak: {},
      newWeekendPeak: {},
      newWeekdayQuiet: {},
      newWeekendQuiet: {},
      newWeekdayNoTransfer: {},
      newWeekendNoTransfer: {},
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
    }),
  },
  created() {
    this.localSettings = JSON.parse(JSON.stringify(this.settings))
  },
  methods: {
    ...mapActions(["TTSpreview"]),
    validateSettings() {
      return new Promise(resolve => {
        this.localSettings.weekday_peak = this.localSettings.weekday_peak.filter(
          time => time.start_time && time.end_time,
        )
        this.localSettings.weekend_peak = this.localSettings.weekend_peak.filter(
          time => time.start_time && time.end_time,
        )

        if (this.localSettings.offpeak_volume > 100) {
          this.localSettings.offpeak_volume = 100
        } else if (this.localSettings.offpeak_volume < 0) this.localSettings.offpeak_volume = 0

        if (this.localSettings.peak_volume > 100) {
          this.localSettings.peak_volume = 100
        } else if (this.localSettings.peak_volume < 0) this.localSettings.peak_volume = 0

        if (this.localSettings.quiet_volume > 100) {
          this.localSettings.quiet_volume = 100
        } else if (this.localSettings.quiet_volume < 0) this.localSettings.quiet_volume = 0

        resolve()
      })
    },
    saveSettings() {
      this.validateSettings().then(() => store.dispatch("saveNextStopSettings", this.localSettings))
    },
    saveForm() {
      store.dispatch("saveNextStopSettings", this.localSettings)
    },
    saveNewWeekdayPeak() {
      if (!this.newWeekdayPeak.start_time || !this.newWeekdayPeak.end_time) return
      this.localSettings.weekday_peak = [...(this.localSettings.weekday_peak || []), this.newWeekdayPeak]
      this.newWeekdayPeak = {}
      store.commit("SET_SNACKBAR", { text: "The new peak has been added, save to apply", color: "secondary" })
    },
    saveNewWeekendPeak() {
      if (!this.newWeekendPeak.start_time || !this.newWeekendPeak.end_time) return
      this.localSettings.weekend_peak = [...(this.localSettings.weekend_peak || []), this.newWeekendPeak]
      this.newWeekendPeak = {}
      store.commit("SET_SNACKBAR", { text: "The new peak has been added, save to apply", color: "secondary" })
    },
    removeWeekdayPeak(peak) {
      const index = this.localSettings.weekday_peak.indexOf(
        this.localSettings.weekday_peak.find(p => p.start_time === peak.start_time && p.end_time === peak.end_time),
      )

      const filteredArray = [
        ...this.localSettings.weekday_peak.slice(0, index),
        ...this.localSettings.weekday_peak.slice(index + 1),
      ]
      this.localSettings = { ...this.localSettings, weekday_peak: filteredArray }

      store.commit("SET_SNACKBAR", { text: "The peak has been removed, save to apply", color: "secondary" })
    },
    removeWeekendPeak(peak) {
      const index = this.localSettings.weekend_peak.indexOf(
        this.localSettings.weekend_peak.find(p => p.start_time === peak.start_time && p.end_time === peak.end_time),
      )

      const filteredArray = [
        ...this.localSettings.weekend_peak.slice(0, index),
        ...this.localSettings.weekend_peak.slice(index + 1),
      ]
      this.localSettings = { ...this.localSettings, weekend_peak: filteredArray }

      store.commit("SET_SNACKBAR", { text: "The peak has been removed, save to apply", color: "secondary" })
    },
    saveNewWeekdayQuiet() {
      if (!this.newWeekdayQuiet.start_time || !this.newWeekdayQuiet.end_time) return
      if (this.newWeekdayQuiet.start_time >= this.newWeekdayQuiet.end_time) return
      this.localSettings.weekday_quiet = [...(this.localSettings.weekday_quiet || []), this.newWeekdayQuiet]
      this.newWeekdayQuiet = {}
      store.commit("SET_SNACKBAR", { text: "The new quiet time has been added, save to apply", color: "secondary" })
    },
    saveNewWeekendQuiet() {
      if (!this.newWeekendQuiet.start_time || !this.newWeekendQuiet.end_time) return
      if (this.newWeekendQuiet.start_time >= this.newWeekendQuiet.end_time) return
      this.localSettings.weekend_quiet = [...(this.localSettings.weekend_quiet || []), this.newWeekendQuiet]
      this.newWeekendQuiet = {}
      store.commit("SET_SNACKBAR", { text: "The new quiet time has been added, save to apply", color: "secondary" })
    },
    removeWeekdayQuiet(quiet) {
      const index = this.localSettings.weekday_quiet.indexOf(
        this.localSettings.weekday_quiet.find(p => p.start_time === quiet.start_time && p.end_time === quiet.end_time),
      )

      const filteredArray = [
        ...this.localSettings.weekday_quiet.slice(0, index),
        ...this.localSettings.weekday_quiet.slice(index + 1),
      ]
      this.localSettings = { ...this.localSettings, weekday_quiet: filteredArray }

      store.commit("SET_SNACKBAR", { text: "The quiet time been removed, save to apply", color: "secondary" })
    },
    removeWeekendQuiet(quiet) {
      const index = this.localSettings.weekend_quiet.indexOf(
        this.localSettings.weekend_quiet.find(p => p.start_time === quiet.start_time && p.end_time === quiet.end_time),
      )

      const filteredArray = [
        ...this.localSettings.weekend_quiet.slice(0, index),
        ...this.localSettings.weekend_quiet.slice(index + 1),
      ]
      this.localSettings = { ...this.localSettings, weekend_quiet: filteredArray }

      store.commit("SET_SNACKBAR", { text: "The quiet time has been removed, save to apply", color: "secondary" })
    },
    saveNewWeekdayNoTransfer() {
      if (!this.newWeekdayNoTransfer.start_time || !this.newWeekdayNoTransfer.end_time) return
      if (this.newWeekdayNoTransfer.start_time >= this.newWeekdayNoTransfer.end_time) return
      this.localSettings.weekday_no_transfer = [
        ...(this.localSettings.weekday_no_transfer || []),
        this.newWeekdayNoTransfer,
      ]
      this.newWeekdayNoTransfer = {}
      store.commit("SET_SNACKBAR", {
        text: "The new no transfer time has been added, save to apply",
        color: "secondary",
      })
    },
    saveNewWeekendNoTransfer() {
      if (!this.newWeekendNoTransfer.start_time || !this.newWeekendNoTransfer.end_time) return
      if (this.newWeekendNoTransfer.start_time >= this.newWeekendNoTransfer.end_time) return
      this.localSettings.weekend_no_transfer = [
        ...(this.localSettings.weekend_no_transfer || []),
        this.newWeekendNoTransfer,
      ]

      this.newWeekendNoTransfer = {}
      store.commit("SET_SNACKBAR", {
        text: "The new no transfer time has been added, save to apply",
        color: "secondary",
      })
    },
    removeWeekdayNoTransfer(noTransferSlot) {
      const index = this.localSettings.weekday_no_transfer.indexOf(
        this.localSettings.weekday_no_transfer.find(
          p => p.start_time === noTransferSlot.start_time && p.end_time === noTransferSlot.end_time,
        ),
      )

      const filteredArray = [
        ...this.localSettings.weekday_no_transfer.slice(0, index),
        ...this.localSettings.weekday_no_transfer.slice(index + 1),
      ]
      this.localSettings = { ...this.localSettings, weekday_no_transfer: filteredArray }

      store.commit("SET_SNACKBAR", { text: "The no transfer time been removed, save to apply", color: "secondary" })
    },
    removeWeekendNoTransfer(noTransferSlot) {
      const index = this.localSettings.weekend_no_transfer.indexOf(
        this.localSettings.weekend_no_transfer.find(
          p => p.start_time === noTransferSlot.start_time && p.end_time === noTransferSlot.end_time,
        ),
      )

      const filteredArray = [
        ...this.localSettings.weekend_no_transfer.slice(0, index),
        ...this.localSettings.weekend_no_transfer.slice(index + 1),
      ]
      this.localSettings = { ...this.localSettings, weekend_no_transfer: filteredArray }

      store.commit("SET_SNACKBAR", { text: "The no transfer time has been removed, save to apply", color: "secondary" })
    },
    validateTimeRange(timeCategory) {
      const start = this[`${timeCategory}`].start_time
      const end = this[`${timeCategory}`].end_time
      const isValid = !start || !end || start < end
      this[`${timeCategory}Error`] = isValid ? null : "Start must be before end"
    },
  },
  setup() {
    const currentTab = ref(null)

    return {
      currentTab,
      icons: {
        mdiVolumeOff,
        mdiClockOutline,
        mdiCalendarRemoveOutline,
        mdiTextToSpeech,
        mdiVolumeHigh,
        mdiCog,
        mdiPlayCircle,
        mdiCloseCircleOutline,
        mdiCheck,
        mdiSpeakerWireless,
      },
    }
  },
}
</script>
