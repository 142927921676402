<template>
  <div class="tool-tip-at-bottom">
    <span>Used for text-to-speech generation</span>
    <v-dialog v-model="isDialogVisible" width="800px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          x-small
          v-bind="attrs"
          elevation="0"
          class="font-weight-semibold link primary--text no-text-transform"
          v-on="on"
        >
          Pronunciation Help Guide
          <v-icon color="primary" size="20" class="ml-1">
            {{ mdiInformationOutline }}
          </v-icon>
        </v-btn>
      </template>
      <v-card small class="text-xs">
        <v-toolbar dense class="text-center">
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-sm"> Pronunciation Help Guide </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon color="primary" @click="isDialogVisible = false">
            {{ mdiClose }}
          </v-icon>
        </v-toolbar>
        <v-card tile>
          <v-card-text>
            <v-alert dense text>
              <p class="text-sm">
                The field supports Speech Synthesis Markup Language (SSML), the following "tags" can be added around
                words and phrases:
              </p>
              <v-list two-line>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content color="info">
                    <v-list-item-title class="text-sm font-weight-semibold">
                      &lt;maori&gt; - Te Reo Māori
                    </v-list-item-title>
                    <div class="text-sm mt-1 mb-1">
                      Example:
                      <code class="pl-2 pr-2">
                        Here is a Te Reo Māori phrase &lt;maori&gt;Kia Ora&lt;/maori&gt;, and here is another one
                        &lt;maori&gt;Naenae&lt;/maori&gt;
                      </code>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content color="info">
                    <v-list-item-title class="text-sm font-weight-semibold"> &lt;b&gt; - Pause </v-list-item-title>
                    <div class="text-sm mt-1 mb-1">
                      Add a pause between words. <br />Example:
                      <code class="pl-2 pr-2"> Next stop is &lt;b&gt; Abilene Crescent at Trilids.</code>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content color="info">
                    <v-list-item-title class="text-sm font-weight-semibold">
                      &lt;ipa&gt; - Phonetic pronunciation (International Phonetic Alphabet)
                    </v-list-item-title>
                    <div class="text-sm mt-1 mb-1">
                      Add the <b>ph</b> attribute to specify the phonetic symbols for pronunciation.<br />
                      Example:
                      <code class="pl-2 pr-2"> You say, &lt;ipa ph="pɪˈkɑːn"&gt;pecan&lt;/ipa&gt;. </code><br />
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content color="info">
                    <v-list-item-title class="text-sm font-weight-semibold">
                      &lt;fast&gt; and &lt;slow&gt; - Control speaking rate
                    </v-list-item-title>
                    <p class="text-sm mt-1 mb-1">
                      Example:
                      <code class="pl-2 pr-2">
                        For dramatic purposes, you might wish to &lt;slow&gt;slow down your speech&lt;/slow&gt;
                      </code>
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content color="info">
                    <v-list-item-title class="text-sm font-weight-semibold">
                      &lt;loud&gt; and &lt;soft&gt; - Control speaking volume
                    </v-list-item-title>
                    <div class="text-sm mt-1 mb-1">
                      Example:
                      <code class="pl-2 pr-2">
                        For dramatic purposes, you might wish to &lt;loud&gt;increase the volume up your
                        voice&lt;/loud&gt;
                      </code>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-list>
            </v-alert>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiInformationOutline, mdiClose } from "@mdi/js"

export default {
  name: "TooltipForTextToSpeechField",
  props: {
    iconLabel: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      mdiInformationOutline,
      mdiClose,
    }
  },
  setup() {
    const isDialogVisible = false

    return {
      isDialogVisible,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/preset/preset/variables.scss";

.v-list-item code {
  display: inline-block !important;
  margin-top: 8px !important;
}
.v-list-item code.second-code-block {
  margin-top: 0 !important;
}
.link.primary--text {
  color: var(--v-primary-base) !important;
}
.tool-tip-at-bottom {
  font-size: small;
  padding-left: 0.5em !important;
  text-align: left;
}
.no-text-transform {
  text-transform: unset !important;
}
</style>
