<template>
  <div class="d-flex align-center justify-center">
    <v-btn color="primary" x-large @click="saveAction"> Save </v-btn>
  </div>
</template>

<script>
export default {
  name: "SaveButton",
  props: {
    saveAction: {
      type: Function,
      default: null,
    },
  },
}
</script>

<style></style>
