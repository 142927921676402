var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"next-stop-pronunciations"}},[(_vm.is_loading)?_c('div',{staticClass:"inline-loader"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1):_c('div',[_c('v-card',[_c('v-card-text',{attrs:{"align":"end"}},[_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","elevation":"0"}},on),[_vm._v(" Add Pronunciation ")])]}}]),model:{value:(_vm.isPronunciationAddVisible),callback:function ($$v) {_vm.isPronunciationAddVisible=$$v},expression:"isPronunciationAddVisible"}},[_c('v-card',[_c('v-card-title',[_vm._t("title",function(){return [_vm._v("Add a new pronunciation")]})],2),_c('v-card-subtitle',{staticClass:"pt-2 px-5"},[_vm._v(" Single words only. For multiple words, create separate pronunciations. ")]),_c('v-card-text',[_c('v-form',{ref:"pronunciation_form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return (function () {}).apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Word","rules":[_vm.pronunciation_rules.required],"required":""},model:{value:(_vm.newPronunciation.name),callback:function ($$v) {_vm.$set(_vm.newPronunciation, "name", $$v)},expression:"newPronunciation.name"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"IPA pronunciation"},model:{value:(_vm.newPronunciation.value),callback:function ($$v) {_vm.$set(_vm.newPronunciation, "value", $$v)},expression:"newPronunciation.value"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mb-8",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.TTSpreview(_vm.newPronunciation.value)}}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlayCircle)+" ")])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.isPronunciationAddVisible = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.pronunciation_cannotSave},on:{"click":function($event){_vm.addPronunciation()
                  _vm.isPronunciationAddVisible = false}}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"pronunciations"},[[_c('v-data-table',{attrs:{"headers":_vm.pronunciationsHeader,"items":_vm.pronunciations,"items-per-page":15,"sort-by":_vm.pronunciationSortBy},on:{"update:sortBy":function($event){_vm.pronunciationSortBy=$event},"update:sort-by":function($event){_vm.pronunciationSortBy=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.value",fn:function(ref){
                  var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.value,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "value", $event)},"update:return-value":function($event){return _vm.$set(item, "value", $event)},"save":function($event){return _vm.updatePronunciation(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","autofocus":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]},proxy:true}],null,true)},[_c('v-chip',[_vm._v(" "+_vm._s(item.value)+" ")])],1)]}},{key:"item.remove",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"elevation":"0","fab":"","x-small":""},on:{"click":function($event){return _vm.IPApreview(item.value)}}},[_c('v-icon',{staticClass:"d-flex justify-center align-center",attrs:{"size":"22","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlayCircle)+" ")])],1),_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var item = ref.item;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","fab":"","x-small":""}},'v-btn',item,false),on),[_c('v-icon',{staticClass:"d-flex justify-center align-center",attrs:{"size":"22","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloseCircleOutline)+" ")])],1)]}}],null,true),model:{value:(_vm.isPronunciationDeleteConfirmationVisible[item.name]),callback:function ($$v) {_vm.$set(_vm.isPronunciationDeleteConfirmationVisible, item.name, $$v)},expression:"isPronunciationDeleteConfirmationVisible[item.name]"}},[_c('v-card',[_c('v-card-title',[_vm._t("title",function(){return [_vm._v("Delete this pronunciation?")]})],2),_c('v-card-text',[_vm._v(" Are you sure you want to delete "),_c('strong',[_vm._v(_vm._s(item.name))]),_vm._v("? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary","outlined":""},on:{"click":function($event){_vm.isPronunciationDeleteConfirmationVisible[item.name] = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.removePronunciation(item)
                            _vm.isPronunciationDeleteConfirmationVisible[item.name] = false}}},[_vm._v(" Yes, Delete ")])],1)],1)],1)]}}],null,true)})]],2)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }